.heroImage {
    width: 100%;
    aspect-ratio: 1 / 1;
    display: block;
    object-position: center;
    object-fit: cover;
}

.blur-load {
    background-size: cover;
    background-position: center;
}

.blur-load.loaded > .heroImage {
    opacity: 1;
}

.blur-load > .heroImage {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
}